<template>
  <div :class="{ 'tickets-style': true, 'disabled-ticket': tickets.disabled,'no-seleccionable':true }">
    <div class="pestana"></div>

    <div class="wrap-content">
      <div class="quality">
        <span
          :class="{
            'shake-horizontal': animation,
            cero: tickets.quantity == 0,
          }"
          style="color: #FF1744"
          v-if="!tickets.isFree"
          >{{ tickets.quantity }}</span
        >
        <span
          style="color: #00cdbc"
          :class="{ 'shake-horizontal': animation }"
          v-else
          >{{ tickets.quantity }}</span
        >
      </div>
      <div class="info-ticket">
        <div
          v-if="!tickets.isFree"
          class="flag-status"
          :class="{
            red: tickets.isSoldOut,
            free: tickets.isFree,
            isPresale: tickets.isPresale,
          }"
        >
          <span v-if="tickets.isSoldOut">AGOTADO</span>

          <span v-else-if="tickets.isPresale">PREVENTA</span>
        </div>
          <span  class=" text-style-tickets" style="" v-if="tickets.disabled && !tickets.preSaleisAvailable">VENTA TERMINADA</span>
          <span  class=" text-style-tickets" style="" v-if="tickets.disabled && tickets.preSaleisAvailable" >VENTA PROXIMAMENTE</span>
        <span class="name text-style-tickets">{{ tickets.name }}</span>
        <span class="price text-style-tickets" v-if="!tickets.isFree">
          {{ tickets.price | currency }}</span
        >
        <span
          class="price text-style-tickets"
          v-else
          style="color: #00cdbc !important"
        >
          {{ tickets.price | currency }}</span
        >
      </div>
      <div class="control-tickets">
        <div
          :class="{
            add: true,
            'btn-tickets-normal': true,
            'btn-tickets-free': tickets.isFree,
          }"
          @click="add"
          style="border-bottom: #594f65 1px solid !important"
        >
          +
        </div>
        <div  :class="{
            remove: true,
            add: true,
            'btn-tickets-normal': true,
            'btn-tickets-free': tickets.isFree,
          }" @click="remove">_</div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment-timezone";
import "moment/locale/es";

import { mapActions, mapState } from "vuex";
moment.locale("es");
import { db, fb } from "@/firebase";
export default {
  name: "sections",
  props: ["tickets","fromCheckout"],

  
  data() {
    return {
      animation: false,
    };
  },
  filters: {
    dateEventStart(date) {
      return moment(date).format("MMM").slice(0, -1);
    },
    DayEventStart(date) {
      return moment(date).format("DD");
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
  },
  methods: {
    ...mapActions(["Alert_"]),
    remove() {
      if (this.tickets.quantity > 0) {
        this.tickets.quantity = this.tickets.quantity - 1;
        this.$emit("Calcu");
      } else {
      }
    },
    add() {
      if (this.tickets.quantity < this.tickets.maxLimitPerUser) {
        this.tickets.quantity = this.tickets.quantity + 1;
        this.$emit("Calcu");
      } else {
        this.animation = true;
        this.Alert_({
          text: "Limite de disponibilidad alcanzado",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
        setTimeout(() => {
          this.animation = false;
        }, 1000);
      }
    },
  },
  async mounted() {
    if (!this.fromCheckout) {
          this.tickets.quantity = 0;
    }

  },
};
</script>
<style lang="scss" scoped>
.tickets-style {
  width: 100%;
  height: 120px;
  position: relative;
  background-color: #332a3d;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  .pestana {
    height: 32px;
    width: 32px;
    border-radius: 15px;
    position: absolute;
    background-color: #282134;
    left: -25px;
    z-index: 100;
  }
  .wrap-content {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-items: center;
    justify-content: space-between;
    .quality {
      width: 20%;
      background-color: #4b4255;
      display: flex;
      border-radius: 10px 0px 0px 10px;
      align-items: center;
      justify-content: center;
      span {
        font-weight: 900;
        font-size: 2.5em;
        color: #fff;
        margin-left: 5px;
      }
    }
    .info-ticket {
      position: relative;
      width: 65%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      .text-style-tickets {
        color: #fff;
        margin-left: 25px;
      }
      .red {
        background-color: #cf0707;
      }
      .free {
        background-color: #00cdbc;
      }
      .isPresale {
        background-color: #00cdbc;
      }
      .flag-status {
        position: absolute;
        left: 0;
        height: 100%;

        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 800;
        color: #fff;
        span {
          writing-mode: vertical-rl !important;
          transform: rotate(180deg);
        }
      }

      .name {
        font-size: 1em;
        font-weight: 900;
        color: #fff;
      }
      .price {
        font-size: 1.2em;
        font-weight: 800;
        color: #FF1744;
      }
    }
    .control-tickets {
      width: 15%;
      height: 100%;
      background-color: #4b4255;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .remove {
        padding-bottom: 35%;
      }
      .add {
        width: 100%;
        height: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        font-size: 2em;
        font-weight: 900;
        align-items: center;
      }
      .btn-tickets-normal {
        color: #FF1744;
        &:active {
          color: #fff;
          background-color: #FF1744;
        }
      }
      .btn-tickets-free {
        color: #00cdbc !important;
        &:active {
          color: #fff !important;
          background-color: #00cdbc !important;
        }
      }
    }
  }
}
.cero {
  color: #fff !important;
}
.shake-horizontal {
  -webkit-animation: shake-horizontal 0.8s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
.disabled-ticket {
  pointer-events: none;
  opacity: 0.5;
}
.no-seleccionable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}
</style>