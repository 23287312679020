<template>
  <v-container class="mt-12 pt-12">
    <h2 style="color: #fff">Mis Tickets</h2>
    <div v-if="ticketGroup.length > 0">
      <v-card class="mt-2" v-for="i in ticketsOrder" :key="i[`.key`]">
        <v-card-title class="titulo">
          <v-row class="">
            <v-col md="6" cols="12">
              <v-avatar class="mr-2">
                <v-img
                  :src="i.event.eventCover?.original "
                  :lazy-src="i.business.businessLogo?.loading"
                ></v-img>
              </v-avatar>
              {{ i.event.name }} <br />
              <b style="font-size: 15px">
                Organizador:
                <span style="color: #FF1744">
                  {{ i.business.businessName }}
                </span>
              </b>
            </v-col>

            <v-col md="6" cols="12" class="flex-end">
              <div class="info-text-Card">
                Lugar:
                <span style="color: #FF1744"> {{ i.event.venueName }}</span
                ><br />
                Fecha:
                <span style="color: #FF1744">
                  {{ i.event.date | date }} - {{ i.event.time | time }}</span
                ><br />
                Ciudad:
                <span style="color: #FF1744"> {{ i.cityName }}</span>
                <br />
                <v-divider class="my-1"></v-divider>

                <span class="mt-2"
                  >Comprado:
                  <b style="color: #FF1744">
                    {{ i.createdAt | dateFormat }}</b
                  ></span
                >
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text >
          <v-data-table
            :headers="headers"
            :items="i.tickets"
            hide-default-footer
            class="elevation-0"
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                :color="item.status == 'available' ? 'info' : 'error'"
                small
              >
                <span>
                  {{ item.status | statusFilter }}
                </span>
              </v-chip>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              {{ item.price | currency }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                @click="generateQrOrder(item)"
                height="50"
                class="my-1"
                block
                color="primary"
              >
                Ticket QR <v-icon small class="ml-2"> fa fa-qrcode </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <!-- <v-card-actions>
          <v-btn
            block
            text
            dark
            :color="i.showTicket?'primary':'#fff'"
            style="border-radius: 10px; text-transform: Capitalize;"
            
            @click="i.showTicket = !i.showTicket"
            >{{ i.showTicket ? "Ocultar" : "Mostrar" }}  <span class="mx-2" style="  text-transform: lowercase;">x{{ i.tickets.length }}</span> 
            tickets
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </div>
    <h1 v-else style="color: #fff" class="text-center">
      😬 No tienes entradas
    </h1>
    <v-dialog persistent v-model="qrDialog" v-if="qrDialog" max-width="400">
      <generate-qr
        @cancel="qrDialog = false"
        :info="selectTickets"
        :code="selectTicketsQr"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import { mapActions, mapState } from "vuex";
import "moment/locale/es";
import generateQr from "@/components/generateQR.vue";
moment.locale("es");

export default {
  name: "history",
  components: {
    generateQr,
  },
  data() {
    return {
      tickets: [],
      ticketsOrder: [],
      qrDialog: false,
      selectTicketsQr: null,
      selectTickets: null,
      headers: [
        { text: "Nombre", value: "ticketName" },
        // { text: "evento", value: "eventName" },
        { text: "Sección", value: "section" },
        { text: "Estado", value: "status" },
        // { text: "Ciudad", value: "cityName" },
        { text: "Precio", value: "price" },
        { text: "Ticket QR", width: "200px", value: "actions" },
      ],
    };
  },
  filters: {
    statusFilter(status) {
      const stateF = {
        available: "Disponible",
        used: "Redimido",
      };
      return stateF[status] || "no disponible";
    },
    date(date) {
      return moment(date).format("DD/MM/YY");
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YY - hh:mm a");
    },
    time(time) {
      return moment(time, "HH:mm").format("hh:mm a");
    },
    currency(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
    total(alltickets) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      let sumaprice = 0;
      for (let i = 0; i < alltickets.length; i++) {
        const element = alltickets[i];
        sumaprice += element.price + (element.ticketFees ?? 0);
      }
      return `L ${formatter.format(sumaprice)}`;
    },
  },
  computed: {
    ...mapState([
      "selectEvent",
      "user",
      "orderRespal",
      "phoneUser",
      "SessionTimer",
      "sessionToken",
    ]),
    ticketGroup() {
      this.qrDialog = false;
      var arrayObjet = this.tickets.reduce((r, a) => {
        const { orderId } = a;
        r[orderId] = r[orderId] ?? [];
        r[orderId].push(a);
        return r;
      }, Object.create(null));

      let Entradas = [];
      for (const key in arrayObjet) {
        if (Object.hasOwnProperty.call(arrayObjet, key)) {
          const element = arrayObjet[key];

          let item = {
            createdAt: moment(element[0].createdAt.toDate())
              .tz("America/Tegucigalpa")
              .format(),
            cityName: element[0].cityName,
            tickets: element,
            showTicket: false,
            business: {
              businessLogo: element[0].businessLogo,
              businessName: element[0].businessName,
            },
            event: {
              name: element[0].eventName,
              venueName: element[0].venueName,
              eventCover: element[0].eventCover,
              date: element[0].date,
              time: element[0].time,
            },
          };

          Entradas.push(item);
        }
      }
      function compare(a, b) {
        if (a.createdAt < b.createdAt) return 1;
        if (a.createdAt > b.createdAt) return -1;
        return 0;
      }
      this.ticketsOrder = Entradas.sort(compare);
      return Entradas.sort(compare);
    },
  },

  methods: {
    generateQrOrder(ticket) {

      this.selectTickets = ticket;
      this.selectTicketsQr = `${ticket[`.key`]}+${this.user[`.key`]}`;
      this.qrDialog = true;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.user) {
        this.$binding(
          "tickets",
          db
            .collection("tickets")
            .where("userId", "==", this.user[`.key`])
            .where("type", "==", "oVive")
        );
      } else {
        this.$router.push("/");
      }
    });
  },
};
</script>

<style lang="scss">
@import "@/_responsive.scss";
.control-city {
  padding: 10px;
  background-color: #20172b;
  border-radius: 15px;
}
.stiky-b {
  position: sticky;
  top: 32px;
  z-index: 100;
}
.carrusel-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .control-container {
    position: absolute;
    bottom: 50px;
    z-index: 100;
  }
}
.info-text-Card {
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  background-color: #20172b;
  border-radius: 10px;
  font-weight: 400;
  @include responsive(mobile) {
    width: 100%;
  }
}
.wrap-between {
  display: flex;
  justify-content: space-between;
}
</style>
