<template>
  <v-app>
    <Preloader />
    <Snackbar style="z-index: 10000 !important" class="Snack" />
    <NAV style="z-index: 500" @openDialog="dialogNewUser = true" />
    <v-main>
      <transition-page>
        <router-view />
      </transition-page>
    </v-main>
    <v-divider></v-divider>
    <Footer />
    <v-dialog
      persistent
      v-model="dialogNewUser"
      v-if="dialogNewUser"
      max-width="400"
    >
      <v-card>
        <v-card-title> Completa tu perfil </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              style="border-radius: 10px"
              v-model="newUser.name"
              required
              label="Nombre"
              :rules="textRule"
              @keydown="isString($event)"
              filled
              rounded
            ></v-text-field>
            <v-text-field
              style="border-radius: 10px"
              v-model="newUser.surname"
              required
              :rules="textRule"
              @keydown="isString($event)"
              label="Apellidos"
              filled
              rounded
            ></v-text-field>
            <v-text-field
              style="border-radius: 10px"
              v-model="newUser.email"
              required
              :rules="emailRules"
              label="Email"
              filled
              rounded
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-btn color="primary" block height="50px" @click="updateProfile">
            continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Snackbar from "@/components/snackbar";
import NAV from "@/components/nav";
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import Footer from "@/components/footer.vue";
import Preloader from "@/components/preloader";
import TransitionPage from "@/components/transitionPage";
export default {
  name: "App",
  components: {
    Snackbar,
    TransitionPage,
    Footer,
    Preloader,
    NAV,
  },
  data: () => ({
    valid: true,
    loading: false,
    logoCustom: require("@/assets/viveFlat.svg"),

    imagedefaul: require("@/assets/templateImage.svg"),
    userPhoto: require("@/assets/user.svg"),
    dataUser: [],
    dialogNewUser: false,
    newUser: {
      name: null,
      surname: null,
      email: null,
      completedProfile: true,
      picture:
        "https://firebasestorage.googleapis.com/v0/b/el-ocho.appspot.com/o/user.svg?alt=media&token=71ed355e-2927-4911-adf0-50bc131dd8c7",
    },
    textRule: [(v) => !!v || "Requerido"],
    emailRules: [
      (v) => !!v || "Email es requerido",
      (v) => /.+@.+\..+/.test(v) || "Ingrese un email valido",
    ],
    //
  }),
  watch: {
    $route(e) {},
  },
  mounted() {
    // console.debug(navigator.platform)
    // console.log(process.env.NODE_ENV);

    this.$nextTick(() => {
      var getDomain = document.domain;
      var search = getDomain.search("8vive");
      if (search != -1) {
        window.location.href = "https://ochovive.com/";
      }
      this.$vuetify.theme.dark = true;
      fb.auth().onAuthStateChanged(async (authUser) => {
        if (authUser) {
          var currentUser = await fb.auth().currentUser.getIdTokenResult();
          // console.debug(currentUser.claims.userId);
          this.$binding(
            "dataUser",
            db.collection("users").doc(currentUser.claims.userId)
          ).then((user) => {
            // console.debug(user);
            this._user(user);
            if (!user.completedProfile) {
              this.dialogNewUser = true;
            }
          });
        } else {
          // console.debug("no registrado");
        }
      });
    });
  },
  computed: {
    ...mapState(["darkmode", "user"]),

    isDark() {
      this.$vuetify.theme.dark = this.darkmode;
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    ...mapActions(["mode", "_user"]),
    updateProfile() {
      this.$refs.form.validate();
      // console.debug(this.user[`.key`]);
      if (this.$refs.form.validate()) {
        db.collection("users")
          .doc(this.user[`.key`])
          .update(this.newUser)
          .then(async () => {
            await db
              .collection("users")
              .doc(this.user[`.key`])
              .collection("addresses")
              .add({
                name: "Dirección",
                neighborhood: "",
                reference: "",
                type: "office",
                geo: {
                  geohash: "d4d00nw3b",
                  geopoint: new fb.firestore.GeoPoint(14.0985425, -87.1788993),
                },
                zone: "Zona Centro",
                city: "OBTiV1PEJckU8WRPXyvz",
                country: "8HxIs06o3vDI2woWpqw3",
                deleted: false,
                default: true,
                isDemo: true,
              })
              .then((resp) => {
                console.debug(resp);
              });

            await this.$binding(
              "dataUser",
              db.collection("users").doc(this.user[`.key`])
            ).then((user) => {
              // console.debug(user);
              this._user(user);
            });
            this.dialogNewUser = false;
          });
      }
    },
    isString: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode != 32 && charCode < 65 && charCode >= 48) ||
        (charCode > 90 && charCode < 97) ||
        charCode > 122
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.disclamer {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  font-size: 12px;
  z-index: 10;
  background-color: #160819;
  color: #fff !important;
  padding: 3px 10px;
  text-align: center;
  border-top: #7b7b7b3d solid 1px;
}
.Snack {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #160819;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: rgba(242, 242, 242, 0.249) !important;
  border-radius: 2px;
}
body {
  background-color: #160819 !important;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slideX-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slideX-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slideX-fade-enter-from,
.slideX-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.app-menu {
  height: 75vh;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 200px;
}
</style>
