<template>
  <div class="event mb-12ewd`143ju2 bv" v-if="!loading">
    <v-container class="pt-12 mt-12">
      <v-img :src="event.cover?.original || imgTemplate" :height="isMobile ? '180px' : '350px'"
        style="border-radius: 10px">
      </v-img>
    </v-container>

    <v-container>
      <div class="stiky-btn">
        <h1 class="text-center" style="color: #fff">{{ event.name }}</h1>
        <!-- <v-divider></v-divider> -->
        <v-btn color="primary" block @click="dialogTicket = true" height="50"
          v-if="event.isTicketRequired && allTickets.length > 0">
          <!-- @click="scrollYm('tickets')" -->
          Tickets para el evento
        </v-btn>
      </div>

      <v-row class="mt-6">
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-card>
            <v-card-title v-if="business">
              <v-avatar class="mr-4">
                <v-img :src="business.logo.original || imgTemplate"
                  :lazy-src="business.logo.loading || imgTemplate"></v-img>
              </v-avatar>
              {{ business.shortName }}
            </v-card-title>

            <v-card-text>
              <h2 style="color: #fff">{{ selectEvent.name }}</h2>
              <v-icon small class="mr-2">fa-thin fa-tag</v-icon>
              <span style="color: #fff" v-for="(i, index) in categoriesEvent" :key="index">{{ i
              }}<span v-if="index < categoriesEvent.length - 1">,
                </span></span>
              <br />
              <v-icon small class="mr-2">fa-thin fa-calendar-week</v-icon>
              <span style="color: #fff">{{ dateEventFromat }} </span> <br />
              <v-icon small class="mr-2">fa-thin fa-users</v-icon>
              <span style="color: #fff">{{ event.ratingAudience }} </span>
              <br /><br />
              <b style="color: #fff">Sobre el evento</b> <br />
              <span>{{ event.description }}</span><br />
              <b style="color: #fff" v-if="event.instructions?.length > 0">Instrucciones y Regulaciones</b>
              <br />
              <ul v-if="event.instructions?.length > 0">
                <li v-for="(e, i) in event.instructions" :key="i">
                  {{ e }}
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <h2 style="color: #fff; display: flex;">Lugar  <v-spacer></v-spacer> </h2>

          <v-img :src="event.venueMap || imgTemplate" style="border-radius: 15px" height="300px"
            @click="openDialogImg(event.venueMap)">
            <div class="wrap-btn-footer">
             
              <v-btn v-if="event.address" small fab absolute right class="mt-4" color="#292134" :href="
                'https://www.google.com/maps/search/?api=1&query=' +
                event.address.geopoint.latitude +
                ',' +
                event.address.geopoint.longitude
              " target="_blank">
                <v-icon small>fa-solid fa-map-location-dot</v-icon>
            
              </v-btn>
              <div class="label-text">
                <span>{{ event.venueName }}</span>
              </div>
            </div>
          </v-img>
          <div class="carr-container mt-6" style="z-index: 100">
            <v-img style="border-radius: 10px" class="img-carr mx-2" v-for="(item, index) in event.images" :key="index"
              :src="item || imgTemplate" width="250px" @click="openDialogImg(item)" height="150px">
            </v-img>
          </div>
        </v-col>
      </v-row>

      <!-- :class="{ 'disabled-actions': user ? !user.completedProfile : true }" -->
    </v-container>

    <v-dialog fullscreen v-model="dialogTicket" transition="dialog-bottom-transition"
      style="z-index: 1000 !important; border: none !important" scrollable persistent>
      <v-card style="
            border-radius: 0px !important;
            background-color: #1b141d !important;
          ">
        <v-card-title>
          <h2 style="color: #fff" class="font-weight-blac">Tickets</h2> <v-spacer></v-spacer>
          <v-btn small @click="openDialogImg(event.venueMap)"  class="mr-12"  color="primary">
                Ver mapa <v-icon small class="ml-2">
                  fa-light fa-map
                </v-icon>
              </v-btn>

          <v-btn icon color="primary" absolute top right @click="dialogTicket = false">
            <v-icon> fa fa-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0">
          <v-container class="mt-2" ref="tickets" v-if="event.isTicketRequired && allTickets.length > 0">
          
          
            <v-stepper elevation="0" color="#00cdbc" v-model="e6" vertical dark>
              <v-stepper-step :complete="e6 > 1" step="1">
                <span v-if="!select_date"> Escoge una fecha </span>
                <span v-else>{{ select_date[0].date | dateEventStart }}</span>
                <!-- <small>Fecha del evento</small> -->
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-card class="my-6 py-6">
                  <v-row class="mx-4" justify="start" v-if="scheduleFormat">
                    <v-col cols="12" md="4" sm="4" lg="4" v-for="(i, index) in scheduleFormat" :key="index">
                      <!-- v-if="i[index].date" -->
                      <div :class="{
                        'btn-shedule': true,
                        'active-btn': select_date == i,
                        disabled: i[0].date < toDay,
                      }" @click="(select_date = i), (e6 = 2)">
                        <span class="date-style mr-2 text-uppercase text-trans" style="font-size: 2em">
                          {{ i[0].date | dateSlice }}</span>
                        <div class="dia-mes">
                          <span class="day-style">
                            {{ i[0].date | DayEventStart }}
                          </span>

                          <span class="date-style mes text-uppercase text-trans">{{ i[0].date | date }}</span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-stepper-content>

              <v-stepper-step :complete="e6 > 2" step="2">
                <span v-if="!select_time"> Escoge una Hora </span>
                <span v-else class="text-uppercase">{{
                  select_time.time | time
                }}</span>
              </v-stepper-step>

              <v-stepper-content step="2">
                <v-card class="my-6 py-6">
                  <v-row class="mx-4" justify="start">
                    <v-col cols="12" md="4" sm="4" lg="4" v-for="(i, index) in dateFormatFilter" :key="index">
                      <div :class="{
                        'btn-shedule': true,
                        'active-btn': select_time == i,
                      }" @click="(select_time = i), (e6 = 3)">
                        <span class="time-style text-uppercase">{{
                          i.time | time
                        }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>

                <!-- <v-btn
                  color="primary"
                  @click="e6 = 3"
                  style="border-radius: 10px"
                  :disabled="!select_time"
                >
                  Continuar
                </v-btn> -->

                <v-btn text @click="cancel" class="ml-2"> Cancelar </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="e6 > 3" step="3">
                Tickets
              </v-stepper-step>

              <v-stepper-content step="3">
                <v-card class="my-6" v-if="select_time">
                  <v-tabs v-model="tabSections" background-color="transparent" transparent dark slider-color="#00cdbc"
                    slider-size="3" flat prominent>
                    <v-tab v-for="(item, index) in sectionsTickets" :key="index">
                      <v-badge color="#00cdbc" :value="item.quantity" :content="item.quantity" overlap>
                        <v-chip outlined elevation="2">
                          <b> {{ item.infoSection.name }}</b>
                        </v-chip>
                      </v-badge>
                    </v-tab>
                  </v-tabs>
                  <v-divider></v-divider>
                  <v-tabs-items v-model="tabSections">
                    <v-tab-item v-for="i in sectionsTickets" :key="i.id">
                      <v-card flat>
                        <!-- <v-card-text>{{ item.infoSection.name }}</v-card-text> -->
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" lg="4" sm="6" class="px-1 py-1" md="4" v-for="(ticket, i) in i.tickets"
                              :key="i">
                              <Tickets @Calcu="calcuQuantity" :tickets="ticket" />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
                <!-- <v-btn color="primary" @click="e6 = 1"> Continue </v-btn> -->
                <v-btn color="primary sml-2 " :loading="btnLoading" @click="preOrder" v-if="user"
                  :disabled="!validQuality">
                  Comprar
                </v-btn>
                <v-btn v-else @click="dialogLogin = true" color="primary" :disabled="!validQuality">
                  Comprar
                </v-btn>
                <v-btn text @click="cancel" class="ml-2" :loading="btnLoading">
                  Cancelar
                </v-btn>
              </v-stepper-content>
            </v-stepper>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPrewImg" max-width="500px">
      <v-card>
        <v-img :src="prewImg || imgTemplate"> </v-img>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLogin" v-if="dialogLogin" max-width="350" persistent>
      <Login @closed="dialogLogin = false" @NextOrder="preOrder" />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CardEvent from "../components/card-event";
import Tickets from "../components/tickets";
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import Login from "../components/Login.vue";

export default {
  name: "events",

  components: {
    CardEvent,
    Tickets,
    Login,
  },
  data() {
    return {
      tabSections: null,
      e6: 1,
      isMobile: screen.width <= 800 ? true : false,
      dialogTicket: false,
      dialogLogin: false,
      sectionsTickets: null,
      select_date: null,
      select_time: null,
      toDay: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      schedule: [],
      btnLoading: false,
      allSections: [],
      allTickets: [],
      dialogPrewImg: false,
      prewImg: null,
      loading: true,
      categories: [],
      event: [],
      countries: [],
      allEvent: [],
      imgTemplate: require("@/assets/templateImage.svg"),
      eventsBanners: [],
      business: null,
      cities: [],
      select: ["Foo", "Bar", "Fizz", "Buzz"],
      items: [
        {
          src: "https://firebasestorage.googleapis.com/v0/b/el-ocho-dev-vive/o/Chc51nJh6MTBD81H9QsJ%2Fcover-original.jpeg?alt=media&token=d04ef7b7-d897-49c3-ad4f-36329447a4ef",
        },
      ],
    };
  },
  computed: {
    ...mapState(["selectEvent", "user"]),
    dateFormatFilter() {
      var formatDate = [];
      if (this.select_date) {
        for (let i = 0; i < this.select_date.length; i++) {
          const element = this.select_date[i];
          var search = this.allTickets.filter(
            (x) => x.ticketDate == element[`.key`] && x.active == true
          );
          if (search.length > 0) {
            formatDate.push(element);
          }
        }
        return formatDate;
      }
    },
    validQuality() {
      if (this.sectionsTickets) {
        var valid = this.sectionsTickets.find((x) => x.quantity > 0);
        return valid ? true : false;
      } else {
        return false;
      }
    },
    ticketsQuantity() {
      return this.allTickets.map((e) => {
        e.preSaleisAvailable = false;
        e.position = 0;
        if (e.salesDateEnd < this.toDay) {
          e.disabled = true;
          e.position = 6;
        }
        if (this.toDay < e.salesDateStart) {
          e.disabled = true;
          e.preSaleisAvailable = true;
          e.position = 1;
        }
        if (this.toDay < e.salesDateStart && e.isPresale) {
          e.disabled = true;
          e.preSaleisAvailable = true;
          e.position = 1;
        }
        if (e.isSoldOut) {
          e.disabled = true;
          e.position = 6;
        }
        if (e.availableAmount <= 0) {
          e.disabled = true;
          e.position = 3;
        }
        // var date = fb.firestore.Timestamp.fromDate(new Date(e.salesDateEnd));

        // // console.debug("date", date);
        e.quantity = 0;
        return e;
      });
    },
    scheduleFormat() {
      // var schedule = JSON.parse(JSON.stringify(this.schedule));
      var schedule = [];
      for (let i = 0; i < this.schedule.length; i++) {
        const element = this.schedule[i];
        var search = this.allTickets.filter(
          (x) => x.ticketDate == element[`.key`] && x.active == true
        );
        if (search.length > 0) {
          schedule.push(element);
        }
      }

      function compare(a, b) {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
        return 0;
      }

      schedule.sort(compare);

      var arrayObjet = schedule.reduce((r, a) => {
        r[a.date] = r[a.date] || [];
        r[a.date].push(a);
        // // // console.debug("a", a);
        return r;
      }, Object.create(null));
      const arraySchedule = [];
      for (const key in arrayObjet) {
        if (Object.hasOwnProperty.call(arrayObjet, key)) {
          const element = arrayObjet[key];


          arraySchedule.push(element);

        }
      }

      // console.debug(arraySchedule);
      return arraySchedule;
    },
    categoriesEvent() {
      var cate = [];
      this.categories.forEach((i) => {
        if (this.selectEvent.categories.includes(i[`.key`])) {
          cate.push(i.name);
        }
      });
      return cate;
    },
    dateEventFromat() {
      if (
        moment(this.selectEvent.dateStart, "YYYY-MM-DD").isSame(
          moment(this.selectEvent.dateEnd, "YYYY-MM-DD")
        )
      ) {
        return (
          moment(this.selectEvent.dateStart, "YYYY-MM-DD").format("D") +
          " de " +
          moment(this.selectEvent.dateStart, "YYYY-MM-DD").format("MMMM YYYY")
        );
      } else {
        return (
          moment(this.selectEvent.dateStart, "YYYY-MM-DD").format("D") +
          " de " +
          moment(this.selectEvent.dateStart, "YYYY-MM-DD").format("MMMM") +
          " al " +
          moment(this.selectEvent.dateEnd, "YYYY-MM-DD").format("D") +
          " de " +
          moment(this.selectEvent.dateEnd, "YYYY-MM-DD").format("MMMM YYYY")
        );
      }
    },
  },
  filters: {
    dateSlice(date) {
      return moment(date).format("MMM").slice(0, -1);
    },

    dateEventStart(date) {
      return moment(date).format("ll");
    },
    DayEventStart(date) {
      return moment(date).format("DD");
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
    time(time) {
      return moment(time, "HH:mm").format("hh:mm a");
    },

    date(date) {
      return moment(date).format("YYYY");
    },

    day(date) {
      return moment(date).format("dddd");
    },
  },
  watch: {
    select_date(item) {

      if (this.user == null) {
        // // console.debug("Usuario no logeado");
      }
    },
    select_time(e) {
      if (e) {
        let ArrayTickets = [];
        var tickets = JSON.parse(
          JSON.stringify(
            this.ticketsQuantity.filter(
              (i) =>
                i.ticketDate == this.select_time[`.key`] && i.active == true
            )
          )
        );
        // // console.log(tickets);
        var arrayObjet = tickets.reduce(function (r, a) {
          const { section } = a;
          r[section] = r[section] ?? [];
          r[section].push(a);
          return r;
        }, Object.create(null));

        // // console.debug("arrayObjet", arrayObjet);
        for (const key in arrayObjet) {
          if (Object.hasOwnProperty.call(arrayObjet, key)) {
            const id = key;
            const element = arrayObjet[key];
            // console.debug(element);
            function compare(a, b) {
              if (a.position < b.position) return -1;
              if (a.position > b.position) return 1;
              return 0;
            }
            function orderBy(a, b) {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            }



            let obj = {
              id: id,
              tickets: element.sort(compare).sort(orderBy),
            };
            console.debug("select-time element", element);
            ArrayTickets.push(obj);
          }
        }
        var section = ArrayTickets.map((i) => {
          i.quantity = 0;
          i.infoSection = this.allSections.find((x) => i.id == x[`.key`]);
          return i;
        });

        this.sectionsTickets = section;
      }
    },
  },
  methods: {
    ...mapActions([
      "_selectEvent",
      "Alert_",
      "_orderRespal",
      "_Order",
      "_timerSession",
      "_sessionToken",
    ]),
    getOffset(topAll) {
      var _x = 0;
      var _y = 0;
      while (topAll && !isNaN(topAll.offsetLeft) && !isNaN(topAll.offsetTop)) {
        _x += topAll.offsetLeft - topAll.scrollLeft;
        _y += topAll.offsetTop - topAll.scrollTop;
        topAll = topAll.offsetParent;
      }
      return { top: _y, left: _x };
    },
    sendEvent(event) {
      this._selectEvent(event);
    },

    scrollYm(item) {
      const ref = {
        tickets: this.$refs.tickets,
      };

      var topAll = ref[item];
      // // // console.debug(topAll);
      var y = this.getOffset(topAll).top;
      // // // console.debug(y);
      window.scroll({
        top: y + -100,
        left: 0,
        behavior: "smooth",
      });
    },
    preOrder() {
      this.btnLoading = true;
      var ticket = [];
      this.sectionsTickets.forEach((x) => {
        if (x.tickets.length > 0) {
          for (let index = 0; index < x.tickets.length; index++) {
            const element = x.tickets[index];
            ticket.push(element);
          }
        }
      });

      var searchTickets = ticket.filter((x) => x.quantity > 0);
      // console.debug(searchTickets.length);
      if (searchTickets.length == 0) {
        this.Alert_({
          text: "😬 Debes seccionar almenos un ticket",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
        this.btnLoading = false;
        return;
      }

      var orderTickets = searchTickets.map((t) => {
        var obj = {
          ticketId: t[`.key`],
          amount: t.quantity,
        };
        return obj;
      });

      // // // console.debug("orderTickets", orderTickets);

      this._orderRespal(searchTickets);
      this._Order(orderTickets);

      var httpOviveGetSession = fb
        .functions()
        .httpsCallable("httpOviveGetSession");

      var httpOviveBlockTickets = fb
        .functions()
        .httpsCallable("httpOviveBlockTickets");

      var data = {
        eventId: this.selectEvent[`.key`],
        channel: "web",
      };
      httpOviveGetSession(data).then((response) => {
        this._timerSession(response.data.created);
        this._sessionToken(response.data.session);
        var data = {
          session: response.data.session,
          tickets: orderTickets,
        };

        httpOviveBlockTickets(data)
          .then((res) => {
            // // // console.debug(res);
            setTimeout(() => {
              this.btnLoading = false;
              this.$router.push("/checkout");
            }, 1000);
          })
          .catch((error) => {
            this.btnLoading = false;
            this.Alert_({
              text: error.message,
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
          });
      });
    },
    openDialogImg(item) {
      this.prewImg = item;
      this.dialogPrewImg = true;
    },
    calcuQuantity() {
      for (let i = 0; i < this.sectionsTickets.length; i++) {
        const element = this.sectionsTickets[i];
        var quantity = 0;
        element.tickets.forEach((e) => {
          quantity = quantity + e.quantity;
        });
        element.quantity = quantity;
      }
    },
    cancel() {
      this.e6 = 1;
      this.select_date = null;
      this.select_time = null;
    },
  },
  mounted() {
    this.loading = true;
    this.$nextTick(async () => {
      const valores = window.location.search;
      const urlParams = new URLSearchParams(valores);
      let eventId = urlParams.get("idkey");
      console.debug(eventId)
      // if (eventId) {
      //   // await this.$binding(
      //   //   "event",
      //   //   db
      //   //     .collection("events")
      //   //     .doc(eventId ? eventId : this.selectEvent[`.key`])
      //   //     .then((response) => {
      //   //       this._selectEvent(response);
      //   //     })
      //   // );
      // }

      await this.$binding(
        "allTickets",
        db
          .collection("oViveTickets")
          .where("event", "==", eventId ? eventId : this.selectEvent[`.key`])
          .where("active", "==", true)
          .where("deleted", "==", false)
      );
      await this.$binding(
        "allSections",
        db
          .collection("events")
          .doc(eventId ? eventId : this.selectEvent[`.key`])
          .collection("sections")
          .where("active", "==", true)
      );
      await this.$binding(
        "schedule",
        db
          .collection("events")
          .doc(eventId ? eventId : this.selectEvent[`.key`])
          .collection("dates")
          .where("active", "==", true)
          .where("deleted", "==", false)
      );
      await this.$binding(
        "countries",
        db
          .collection("countries")
          .where("deleted", "==", false)
          .where("active", "==", true)
      );
      await this.$binding(
        "cities",
        db
          .collection("cities")
          .where("deleted", "==", false)
          .where("active", "==", true)
      );
      await this.$binding(
        "event",
        db
          .collection("events")
          .doc(eventId ? eventId : this.selectEvent[`.key`])
      );
      await this._selectEvent(this.event);
      await this.$binding(
        "eventsBanners",
        db
          .collection("eventsBanners")
          .where("deleted", "==", false)
          .where("active", "==", true)
      );
      await this.$binding(
        "business",
        db.collection("businesses").doc(this.selectEvent.business)
      );

      await this.$binding(
        "categories",
        db
          .collection("oViveCategories")
          .where("city", "==", this.selectEvent.city)
          .where("city", "==", this.selectEvent.city)
          .where("deleted", "==", false)
          .where("active", "==", true)
      );
      this.loading = false;
    });
  },
};
</script>

<style lang="scss">
.text-trans {
  text-transform: capitalize !important;
}

.control-city {
  padding: 10px;
  background-color: #20172b;
  border-radius: 15px;
}

.stiky-b {
  position: sticky;
  top: 32px;
  z-index: 100;
}

.carrusel-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .control-container {
    position: absolute;
    bottom: 50px;
    z-index: 100;
  }
}

.text-center {
  text-align: center;
}

.wrap-btn-footer {
  height: 100%;
  width: 100%;
  position: relative;

  .label-text {
    width: 100%;
    height: 40px;
    background: linear-gradient(90deg,
        #292134 0%,
        rgba(35, 24, 37, 0.876) 52%,
        rgba(29, 15, 32, 0.5844712885) 100%,
        rgba(255, 255, 255, 0) 100%);
    position: absolute;
    bottom: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.carr-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  height: auto;
  overflow-x: scroll;
  overflow: auto;
  scroll-snap-type: x mandatory;

  .img-carr {
    min-width: 200px;
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }
}

.theme--dark.v-stepper {
  background: #16081900 !important;
}

.v-application .grey.lighten-1 {
  background-color: #282134 !important;
  border-color: #282134 !important;
}

.grey.lighten-1 {
  background-color: #282134 !important;
  border-color: #282134 !important;
}

.active-btn {
  background-color: #00cdbc !important;
}

.label-sections {}

.disabled-actions {
  pointer-events: none !important;
  opacity: 0.5;
}

.btn-shedule {
  cursor: default; // esto para solucionar un bug en el hover en la fecha y hora de los tickets
  transition: 0.3s ease-in-out;
  height: 80px;
  background-color: #332a3d;
  border-radius: 10px;
  color: #fff;
  box-shadow: #0f0b1498 0px 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .dia-mes {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;

    .mes {
      font-size: 0.8em;
    }
  }

  .day-style {
    font-weight: 900;
    font-size: 1.8em;
    line-height: 20px;
  }

  .date-style {
    font-weight: 900;
    font-size: 1em;
  }

  .time-style {
    font-weight: 900;
    font-size: 1.5em;
    line-height: 0px;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled {
  opacity: 0.2;
  pointer-events: none !important;
  box-shadow: #0f0b1498 0px 0px 0px !important;
}

.stiky-btn {
  position: sticky;
  top: 70px;
  z-index: 100;
  background-color: #160818be;
}
</style>
