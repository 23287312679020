var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'tickets-style': true, 'disabled-ticket': _vm.tickets.disabled,'no-seleccionable':true }},[_c('div',{staticClass:"pestana"}),_c('div',{staticClass:"wrap-content"},[_c('div',{staticClass:"quality"},[(!_vm.tickets.isFree)?_c('span',{class:{
          'shake-horizontal': _vm.animation,
          cero: _vm.tickets.quantity == 0,
        },staticStyle:{"color":"#FF1744"}},[_vm._v(_vm._s(_vm.tickets.quantity))]):_c('span',{class:{ 'shake-horizontal': _vm.animation },staticStyle:{"color":"#00cdbc"}},[_vm._v(_vm._s(_vm.tickets.quantity))])]),_c('div',{staticClass:"info-ticket"},[(!_vm.tickets.isFree)?_c('div',{staticClass:"flag-status",class:{
          red: _vm.tickets.isSoldOut,
          free: _vm.tickets.isFree,
          isPresale: _vm.tickets.isPresale,
        }},[(_vm.tickets.isSoldOut)?_c('span',[_vm._v("AGOTADO")]):(_vm.tickets.isPresale)?_c('span',[_vm._v("PREVENTA")]):_vm._e()]):_vm._e(),(_vm.tickets.disabled && !_vm.tickets.preSaleisAvailable)?_c('span',{staticClass:"text-style-tickets"},[_vm._v("VENTA TERMINADA")]):_vm._e(),(_vm.tickets.disabled && _vm.tickets.preSaleisAvailable)?_c('span',{staticClass:"text-style-tickets"},[_vm._v("VENTA PROXIMAMENTE")]):_vm._e(),_c('span',{staticClass:"name text-style-tickets"},[_vm._v(_vm._s(_vm.tickets.name))]),(!_vm.tickets.isFree)?_c('span',{staticClass:"price text-style-tickets"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.tickets.price)))]):_c('span',{staticClass:"price text-style-tickets",staticStyle:{"color":"#00cdbc !important"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.tickets.price)))])]),_c('div',{staticClass:"control-tickets"},[_c('div',{class:{
          add: true,
          'btn-tickets-normal': true,
          'btn-tickets-free': _vm.tickets.isFree,
        },staticStyle:{"border-bottom":"#594f65 1px solid !important"},on:{"click":_vm.add}},[_vm._v(" + ")]),_c('div',{class:{
          remove: true,
          add: true,
          'btn-tickets-normal': true,
          'btn-tickets-free': _vm.tickets.isFree,
        },on:{"click":_vm.remove}},[_vm._v("_")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }