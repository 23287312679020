import Vue from 'vue';
import Vuetify from 'vuetify';
import es from 'vuetify/lib/locale/es';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: {
            primary: '#FF1744',
            secondary: '#5170b5',
            accent: '#f06a25',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107'
        },
        dark: {
            primary: '#FF1744',
            secondary: '#132B54',
            accent: '#CC581F',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107'
        },
    },
    
  },
    lang: {
      locales: { es },
      current: 'es',
    },
  icons: {
    iconfont: 'fa',
  },
});
