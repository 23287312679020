<template>
  <v-container class="footer">
    <div class="descar">
      <descarga className="btn btn-dark">
        <img
          src="@/assets/pulpito.svg"
          slot="pulpito"
          class="mr-2"
          width="30px"
          alt=""
        />
      </descarga>
    </div>
    <div class="byOcho">
      <img
        class="mt-2" 
        src="@/assets/POWERED BY DILO DELIVERY.svg"
        width="100px"
        alt=""
        srcset=""
      />
    </div>
  </v-container>
</template>

<script>
import descarga from "@/components/btnDescarga.vue";

export default {
  name: "Footer",
  components: {
    descarga,
  },
  props: {
    titulo: {
      typeof: String,
      default: "Titulo",
    },
    label: {
      typeof: String,
    },
    color: {
      typeof: "",
      default: "#000",
    },
  },

  data: () => ({}),
  methods: {},
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/main.sass";

.footer {
  height: 200px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.descar {
  width: auto;
}
.logos-brand {
  display: flex;

  justify-content: space-between;
  width: 300px;
  padding-left: 5px;
  padding-right: 5px;
  align-items: center;
}
.divider {
  height: 30px;
  width: 1px;
  background-color: #1b1b1b;
}
.byOcho {
  width: 200px;
  display: flex;
  justify-content: flex-end;
}
.cta {
  text-decoration: underline !important;
}
</style>
