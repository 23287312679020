<template>
  <div class="home" v-if="this.filterEvent.length > 0">
    <div :class="{ 'carrusel-container': true, 'carrusel-mobile': true }">
      <v-carousel
        :show-arrows="false"
        cycle
        v-if="filterBanner.length > 0"
        :height="isMobile ? '200px' : '500px'"
        hide-delimiter-background
      >
        <v-carousel-item
          v-for="(item, i) in filterBanner"
          :key="i"
          :src="item.cover.original"
          :lazy-src="item.cover.loading"
        >
          <div
            v-ripple
            v-if="item.target.event"
            class="goEvent"
            @click="eventGo(item.target.event)"
          ></div>
        </v-carousel-item>
      </v-carousel>
    </div>
    <!-- <v-container class="control-container stiky-b">
      <div class="control-city mt-6">
        <v-row>
          <v-col md="3" cols="12">
            <v-select
              :items="select"
              label="Selecciona tu ciudad"
              filled
              rounded
              style="border-radius: 10px"
              hide-details
              dense
            ></v-select>
          </v-col>

          <v-col md="3" cols="12">
            <v-select
              :items="select"
              label="Fecha"
              filled
              dense
              rounded
              style="border-radius: 10px"
              hide-details
            ></v-select>
          </v-col>
          <v-col md="4" cols="12">
            <v-select
              :items="select"
              label="Buscar"
              filled
              rounded
              dense
              style="border-radius: 10px"
              hide-details
            ></v-select>
          </v-col>
          <v-col md="2" cols="12">
            <v-btn
              color="primary"
              height="52"
              block
              dense
              style="border-radius: 15px"
            >
              Buscar</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-container> -->
    <v-container>
      <v-card-title > Todos los eventos </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="6"
            v-for="(event, index) in sortedEvent"
            :key="index"
            style="cursor: pointer"
            @click="sendEvent(event)"
          >
            <CardEvent :event="event" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>

    <v-dialog v-model="dialogVip" max-width="350">
      <v-card>
        <v-card-title class="pr-12">
          Ingrese el código del evento

          <v-btn
            fab
            color="primary"
            @click="dialogVip = false"
            icon
            small
            elevation="0"
            right
            absolute
          >
            <v-icon small> fa fa-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            class="mt-6"
            style="border-radius: 10px"
            label="Código del Evento"
            filled
            required
          
            v-model="codeEventVip"
            rounded
            @keyup.enter="validCode"
            hide-details
            dense
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pb-4">
          <v-btn
            color="primary"
            height="50"
            block
            :loading="loading"
            @click="validCode"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div class="not-event" v-else>
    <v-container class="colum">
      <v-progress-circular
        indeterminate
        color="primary"
        size="50"
        class="mb-12"
      ></v-progress-circular>
      <h1>😬 No hay ningún evento para esta ciudad</h1>
    </v-container>
  </div>
</template>

<script>
import CardEvent from "../components/card-event";
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


export default {
  name: "Home",

  components: {
    CardEvent,
  },
  data() {
    return {
      isMobile: screen.width <= 800 ? true : false,
      countries: [],
      allEvent: [],
      dialogVip: false,
      loading: false,
      event_select: null,
      codeEventVip: null,
      eventsBanners: [],
      cities: [],
      select: ["Foo", "Bar", "Fizz", "Buzz"],
      code: null,
      toDay: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      items: [
        {
          src: "https://firebasestorage.googleapis.com/v0/b/el-ocho-dev-vive/o/Chc51nJh6MTBD81H9QsJ%2Fcover-original.jpeg?alt=media&token=d04ef7b7-d897-49c3-ad4f-36329447a4ef",
        },
      ],
    };
  },
  computed: {
    ...mapState(["user", "city", "country", "codeEvent"]),
    filterEvent() {
      if (this.user && this.user?.isTester) {
        return this.allEvent;
      } else {
        return this.allEvent.filter((x) => !x.isTester);
      }
    },
    sortedEvent: function () {
      function compare(a, b) {
        if (a.dateStart < b.dateStart) return -1;
        if (a.dateStart > b.dateStart) return 1;
        return 0;
      }
      return this.filterEvent.sort(compare);
    },
    filterBanner() {
      if (this.user && this.user.isTester) {
        return this.validSchedule(this.eventsBanners);
      } else { 
        var banners = this.validSchedule(this.eventsBanners);
        return banners.filter((x) => !x.isTester);
      }
    },
  },
  methods: {
    ...mapActions(["_selectEvent", "Alert_", "_code"]),
    validSchedule(array) {
      return array.filter(
        (elemnet) =>
          moment(this.toDay).isBetween(
            moment(elemnet.dateStart.toDate()).format("YYYY-MM-DD"),
            moment(elemnet.dateEnd.toDate()).format("YYYY-MM-DD")
          ) && elemnet.target.type == "event"
      );
    },
    sendEvent(event) {
      if (event.isVip) {
        this.event_select = event;
        if (this.codeEvent == event.codeEventVip) {
          this._selectEvent(event);
          setTimeout(() => {
            this.$router.push("/event");
          }, 1000);
        } else {
          this.code = event.codeEventVip;
          this.dialogVip = true;
        }
        return;
      } else {
        this._selectEvent(event);
        setTimeout(() => {
          this.$router.push("/event");
        }, 1000);
      }
    },
    validCode() {
      this.loading = true;
      if (this.codeEventVip) {
        if (this.codeEventVip == this.code) {
          this.dialogVip = false;
          this._selectEvent(this.event_select);
          this._code(this.codeEventVip);
          setTimeout(() => {
            this.$router.push("/event");
          }, 1000);
        } else {
          this.loading = false;
          this.codeEventVip = null;
          this.Alert_({
            text: "😬 El Código no coincide",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "error",
          });
        }
      } else {
        this.loading = false;
        this.codeEventVip = null;
        this.Alert_({
          text: "😬 Ingresa el código requerido",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
      }
    },
    eventGo(e) {
      var event = this.allEvent.find((x) => e == x[`.key`]);
      if (event) {
        this.sendEvent(event);
        return;
      }
    },
    async getEvent() {
      await this.$binding(
        "allEvent",
        db
          .collection("events")
          .where("deleted", "==", false)
          .where("active", "==", true)
          .where("city", "==", this.city[`.key`])
          .where("country", "==", this.country[`.key`])
      );
      await this.$binding(
        "eventsBanners",
        db
          .collection("eventsBanners")
          .where("deleted", "==", false)
          .where("active", "==", true)
          .where("city", "==", this.city[`.key`])
          .where("country", "==", this.country[`.key`])
      );
    },
  },
  watch: {
    city(e) {
      this.getEvent();
    },
  },
  mounted() {
    this.$nextTick(async () => {
      await this.$binding(
        "countries",
        db
          .collection("countries")
          .where("deleted", "==", false)
          .where("active", "==", true)
      );
      await this.$binding(
        "cities",
        db
          .collection("cities")
          .where("deleted", "==", false)
          .where("active", "==", true)
      );
      this.getEvent();
    });
  },
};
</script>

<style lang="scss">
.control-city {
  padding: 10px;
  background-color: #20172b;
  border-radius: 15px;
}
.stiky-b {
  position: sticky;
  top: 32px;
  z-index: 100;
}
.carrusel-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .control-container {
    position: absolute;
    bottom: 50px;
    z-index: 100;
  }
}
.carrusel-mobile {
  margin-top: 70px;
}
.not-event {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: #fff;
    text-align: center;
  }
}
.colum {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
}
.goEvent {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
