<template>
  <v-card style="border-radius: 10px !important" outlined height="100%">
    <v-img :src="event.cover?.original" :lazy-src="imgTemplate" height="220px">
      <v-chip color="primary" class="variousDates" v-if="event.variousDates">
        <b>Varias fechas</b>
      </v-chip>

      <v-chip color="#000" absolute class="ml-2 mt-2"  v-if="event.isVip">Evento Privado</v-chip>
      <div class="bg-wrap">
        <div class="text-card">
          <span v-if="event.basePrice" class="currency">{{ event.basePrice  | currency }}</span
          ><br />
          <b style="color: #fff"> {{ event.name }}</b>
          <!-- <p class="text-caption">{{ event.description }}</p> -->
        </div>

        <div class="date-event--">
          <span class="date">{{ event.dateStart | DayEventStart }}</span>
          <span>{{ event.dateStart | dateEventStart }}</span>
        </div>
      </div>
    </v-img>
  </v-card>
</template>
<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
export default {
  name: "sections",
  props: ["event"],
  data() {
    return {
      countries: [],
      reveal: false,
      imgTemplate : require('@/assets/templateImage.svg')
    };
  },
  filters: {
    dateEventStart(date) {
      return moment(date).format("MMM").slice(0, -1);
    },
    DayEventStart(date) {
      return moment(date).format("DD");
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
  },
  ...mapState(["codeEventId", "user"]),
  async mounted() {
    await this.$binding(
      "countries",
      db
        .collection("countries")
        .where("deleted", "==", false)
        .where("active", "==", true)
    );
  },
};
</script>
<style lang="scss" scoped>
.nav {
  width: 100%;
  position: fixed;
  height: 100px;
  top: 0px;
  z-index: 100;
  background: #160819;
  background: linear-gradient(180deg, #160819 0%, rgba(0, 212, 255, 0) 100%);
}
.bg-card-text {
  background-color: #160819;
  background: linear-gradient(0deg, #160819 0%, rgba(0, 212, 255, 0) 100%);
  position: absolute;
  bottom: 0;
  opacity: 0;
  height: 100%;
  transition: 00.3s ease-in-out;
  &:hover {
    opacity: 1;
  }
}
.bg-wrap {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  padding: 10px;
  background: linear-gradient(
    90deg,
    rgba(22, 8, 25, 1) 0%,
    rgba(22, 8, 25, 0.8757878151260504) 52%,
    rgba(29, 15, 32, 0.5844712885154062) 100%,
    rgba(255, 255, 255, 0) 100%
  );
}
.date-event-- {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 10px !important;
  .date {
    font-size: 1.3em !important;
    font-weight: 600;
  }
  color: #fff;
  span {
    font-weight: 300;
    text-transform: uppercase;
  }
  text-align: center;
}
.text-card {
  width: calc(100% - 12%);
}
.currency {
  font-weight: 400;
  font-size: 1.1rem;
  color: #fff;
}
.variousDates {
  position: absolute;
  right: 10px;
  top: 10px;
}
.isVip {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  // filter: blur(20px);
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 1.8em;
    font-weight: 900;
  }
}
</style>