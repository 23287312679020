
<template>

    <v-row class="form-conat pa-0 ma-0">
      <v-col cols="12" class="pa-0 ma-0">
        <label v-if="value" class="text-caption">Celular</label>
        <v-text-field
          v-if="!value"
          required
          v-model="phone"
          @keydown="isNumber($event)"
          type="tel"
          messages="Para recibir código por SMS"
          class="rounded-lg"
          filled
          @keyup.enter="$emit('actions')"
          full-width
          rounded

          :prefix="`+${Country.callingCodes[0]}`"
        >
          <template v-slot:prepend-inner>
            <div class="wrap-flag">
              <v-menu
                v-if="Country"
                offset-y
                transition="slide-x-transition"
                left
                bottom
                rounded="lg"
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="flag rounded-lg" v-bind="attrs" v-on="on">
                    <img
                      :src="`${require(`@/Countries/` + Country.flag)}`"
                      alt=""
                      srcset=""
                    />

                    <v-icon>mdi-menu-down</v-icon>
                  </div>
                </template>
                <v-list height="250px" class="list" v-if="Countries">
                  <v-list-item
                    v-for="(item, index) in Countries"
                    :key="index"
                    class="list-item"
                    @click="Country = item"
                  >
                    <div class="img-text">
                      <img
                        :src="`${require(`@/Countries/` + item.flag)}`"
                        alt=""
                        srcset=""
                      />
                      <p class="ml-2">
                        <b>{{ item.name }} </b> +{{ item.callingCodes[0] }}
                      </p>
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-text-field>
        <v-text-field
          v-else
          required
          v-model="phone"
          @keydown="isNumber($event)"
          type="tel"
          class="rounded-lg"
          filled
          readonly
          full-width
          hide-details
        @keyup.enter="$emit('actions')"
          rounded
          :prefix="`+${code.callingCodes[0]}`"
        >
          <template v-slot:prepend-inner>
            <div class="wrap-flag">
              <div class="flag rounded-lg mr-2" >
                    <img
                      :src="`${require(`@/Countries/` + code.flag)}`"
                      alt=""
                      srcset=""
                    />

                    <!-- <v-icon>mdi-menu-down</v-icon> -->
                  </div>
            </div>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
</template>
<script>
import countries from "../Countries/countries.json";
import country from "../Countries/country.json";
import { db, fb } from "@/firebase";
export default {
  name: "inputPhone",
  props: ["value","code"],
  data() {
    return {
      Countries: countries,
      Country: country,
      phone: null,
    };
  },

  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  watch: {
    phone(e) {
      this.$emit("retunValue", e, this.Country);
      
    },
  },
  mounted() {
    if (this.value) {
      this.phone = this.value;
    }
  },
};
</script>
<style lang="scss" >
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
    margin-right: auto;
    padding-right: 4px;
    margin-top: 10px !important;
}
.wrap-flag {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;


  .flag {
    height: 36px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40px;
      height: 30px;
      border-radius: 8px;
    }
    .select-flag {
      height: 250px;
      width: 100px;
      left: 0;
      top: 0;
      overflow-y: scroll;
      position: absolute;
      background-color: #f2f2f2;
      z-index: 100;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: rgba(0, 0, 0, 0.435) 2px 2px 20px;
    }
  }
}
.list {
  background-color: #f2f2f2;
  overflow: scroll;
  border-radius: 20px;
}

// @include responsive(desktop) {
// 	font-size: 20px;
// 	//... all what you want
// }
ul {
  padding-left: 5px !important;
  li {
    list-style: none;
  }
}
.list {
  background-color: #f2f2f2;
  overflow: scroll;
  border-radius: 20px;
}
.img-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 30px;
    height: 23px;
    border-radius: 5px;
  }
}
.form-conat {
  width: 100%;
}
.flex-start {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
    margin-right: auto;
    padding-right: 4px;
    margin-top: 10px !important;
}

</style>