
import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
Vue.use(Vuex);
const vuexPersist = new VuexPersist({
  key: "ocho-vive-web",
  storage: window.localStorage,
  modules: [

    "darkmode",
    "selectEvent",
    "user",
    "respalOrder",
    "orderRespal",
    "phoneUser",
    "SessionTimer",
    "sessionToken",
    "country",
    "codeEvent",
    "city",
    "codeArea"

  ],
});

export default new Vuex.Store({
  state: {
    SnackbarAlert: {},
    darkmode: true,
    selectEvent: null,
    user: null,
    codeEvent:null,
    respalOrder: null,
    orderRespal: null,
    SessionTimer: null,
    country: null,
    city: null,
    sessionToken: null,
    phoneUser: null,
    codeArea: null

  },
  mutations: {
    AlertSnackbar(state, config) {
      state.SnackbarAlert = config;
      return state.SnackbarAlert;
    },
    mode(state, mode) {
      state.darkmode = mode;
      return state.darkmode;
    },
    codeVip(state, code) {
      state.codeEvent = code;
      return state.codeEvent;
    },
    country(state, data) {
      state.country = data;
      return state.country;
    },
    codeArea(state, data) {
      state.codeArea = data;
      return state.codeArea;
    },
    Phone(state, data) {
      state.phoneUser = data;
      return state.phoneUser;
    },
    city(state, data) {
      state.city = data;
      return state.city;
    },
    setUser(state, data) {
      state.user = data;
      return state.user;
    },
    timerSession(state, data) {
      state.SessionTimer = data;
      return state.SessionTimer;
    },
    sessionToken(state, data) {
      state.sessionToken = data;
      return state.sessionToken;
    },

    respalOrder(state, data) {
      state.respalOrder = data;
      return state.respalOrder;
    },
    orderRespal(state, data) {
      state.orderRespal = data;
      return state.orderRespal;
    },
    selEvent(state, data) {
      state.selectEvent = data;
      return state.selectEvent;
    },

  },
  actions: {
    Alert_: ({ commit, state }, config) => {
      commit("AlertSnackbar", config);
    },
    mode: ({ commit, state }, mode) => {
      commit("mode", mode);
    },
    _code: ({ commit, state }, code) => {
      commit("codeVip", code);
    },
    _user: ({ commit, state }, data) => {
      commit("setUser", data);
    },
    _country: ({ commit, state }, data) => {
      commit("country", data);
    },
    _codeArea: ({ commit, state }, data) => {
      commit("codeArea", data);
    },
    setPhone: ({ commit, state }, data) => {
      commit("Phone", data);
    },
    _city: ({ commit, state }, data) => {
      commit("city", data);
    },
    _timerSession: ({ commit, state }, data) => {
      commit("timerSession", data);
    },
    _sessionToken: ({ commit, state }, data) => {
      commit("sessionToken", data);
    },
    _orderRespal: ({ commit, state }, data) => {
      commit("orderRespal", data);
    },
    _Order: ({ commit, state }, data) => {
      commit("respalOrder", data);
    },
    _selectEvent: ({ commit, state }, data) => {
      commit("selEvent", data);
    },
  },
  getters: {
    Alert_: (state) => {
      return state.SnackbarAlert;
    },
    selectEvent_: (state) => {
      return state.selectEvent;
    },
    orderRespal: (state) => {
      return state.orderRespal;
    },
    SessionTimer: (state) => {
      return state.SessionTimer;
    },
    sessionToken: (state) => {
      return state.sessionToken;
    },
    phoneUser: (state) => {
      return state.phoneUser;
    },
    Order: (state) => {
      return state.respalOrder;
    },
    user: (state) => {
      return state.user;
    },

  },
  modules: {},
  plugins: [vuexPersist.plugin],
});
