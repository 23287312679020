<template>
  <div class="ticketsCheck">
    <div class="quality">
      <span class="Quantity">{{ tickets.quantity }}x</span>
    </div>
    <div class="wrap-text-content">
      <span class="font-weight-bold"
        >{{ tickets.name }}

        <span style="color: #00cdbc" v-if="tickets.isPresale">
          ( PREVENTA {{ tickets.price | currency }})
        </span>
        <span style="color: #00cdbc" v-else-if="tickets.isFree">
          (GRATIS)
        </span>
        <span v-else style="color: #FF1744">
          ({{ tickets.price | currency }})
        </span>
      </span>
      <span class="font-weight-light">Total ({{ tickets | subTicket }})</span>
    </div>
    <div class="wrap-img" v-if="selectEvent">
      <v-img
        style="border-radius: 10px"
        :src="selectEvent.cover?.original || imgTemplate"
        width="70px"
        height="50px"
      >
      </v-img>

    </div>

    <div class="btn-action">
      <!-- <v-btn width="49%" style="border-radius: 8px" color="#ffdd70">
        <v-icon small> fa fa-pencil </v-icon>
      </v-btn> -->
      <v-btn outlined @click="$emit('deleted',tickets)"  small icon style="border-radius: 8px" color="error">
        <v-icon small color="error"> fa fa-trash </v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import moment from "moment-timezone";
import "moment/locale/es";
import { mapActions, mapState } from "vuex";
moment.locale("es");
import { db, fb } from "@/firebase";
export default {
  name: "sections",
  props: ["tickets", "fromCheckout"],
  data() {
    return {
      animation: false,
           imgTemplate: require("@/assets/templateImage.svg"),
    };
  },
  computed: {
    ...mapState(["selectEvent"]),
  },
  filters: {
    dateEventStart(date) {
      return moment(date).format("MMM").slice(0, -1);
    },
    DayEventStart(date) {
      return moment(date).format("DD");
    },
    subTicket(ticket) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(ticket.price * ticket.quantity)}`;
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
  },
  methods: {
    ...mapActions(["Alert_"]),
    remove() {
      if (this.tickets.quantity > 0) {
        this.tickets.quantity = this.tickets.quantity - 1;
        this.$emit("Calcu");
      } else {
      }
    },
    add() {
      if (this.tickets.quantity < this.tickets.maxLimitPerUser) {
        this.tickets.quantity = this.tickets.quantity + 1;
        this.$emit("Calcu");
      } else {
        this.animation = true;
        this.Alert_({
          text: "Limite de disponibilidad alcanzado",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
        setTimeout(() => {
          this.animation = false;
        }, 1000);
      }
    },
  },
  async mounted() {
    if (!this.fromCheckout) {
      this.tickets.quantity = 0;
    }
  },
};
</script>
<style lang="scss" scoped>
.ticketsCheck {
  width: 100%;
  min-height: 50px;
  background-color: #393244;
  border-radius: 10px;
  display: flex;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  box-shadow: #14121876 0px 3px 5px;
  flex-wrap: wrap;
  .quality {
    width: 10%;
  }
  .wrap-img {
    width: 20%;
  }
  .wrap-text-content {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .btn-action {
    
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Quantity {
  font-weight: 800;
  font-size: 1.2em;
  margin-left: 10px;
}
.shake-horizontal {
  -webkit-animation: shake-horizontal 0.8s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
.disabled-ticket {
  pointer-events: none;
  opacity: 0.5;
}
</style>