<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-card>
      <v-card-title class="grid-close pr-12">
        <span class="headline">Ingresa el código enviado a tu celular</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              
              <div class="ma-auto position-relative" style="max-width: 400px">
                <v-otp-input
                  length="6"
                  type="tel"
                  @finish="next"
                  v-model="otp"
                  :disabled="loading"
                ></v-otp-input>

                <v-overlay color="white" absolute :value="loading">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-overlay>
              </div>
            </v-col>

            <!-- <v-col class="mb-0 pb-0" cols="12" sm="12" md="12">
              <v-btn
                @click="next"
                height="50"
                rounded
                block
                :loading="loading"
                :disabled="!otp"
                color="primary"
                >Verificar</v-btn
              >
            </v-col> -->
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
    
    <script>
import { mapState } from "vuex";

export default {
  name: "MFA-code",
  props: ["auth"],
  components: {},
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      otp: "",
      loading: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    onFinish(e) {
      // console.log(e);
    },
    next() {
      this.$emit("success", this.otp);
      this.loading = true;
    },
    error() {
      this.snackbarText = "Código de verificación incorrecto.";
      this.snackbar = true;
      this.loading = false;
      this.otp = "";
    },
  },
  mounted() {
    // // console.log(this.auth.hints[0].phoneNumber);
  },
};
</script>


<style lang="scss">
.position-relative {
  position: relative;
}
</style>
    
  