<template>
  <div class="nav">
    <v-container class="flex">
      <div class="back">
        <v-btn
          color="#282134"
          fab
          small
          v-if="isBack && btn_checkout"
          @click="(dialogConfirmCloseCheckout = true), (pathBack = '/')"
        >
          <v-icon small> fa-solid fa-chevron-left </v-icon>
        </v-btn>
        <v-btn color="#282134" fab small v-else-if="isBack" link :to="pathBack">
          <v-icon small> fa-solid fa-chevron-left </v-icon>
        </v-btn>
        <img
          src="@/assets/viveFlat.svg"
          class="logo-nav"
          alt=""
          @click="goHome"
        />
      </div>

      <div class="grap-locations" v-if="country">
        <v-btn
          @click="
            btn_checkout
              ? (dialogConfirmCloseCheckout = true)
              : (dialogCountries = true)
          "
          small
          dark
          color="#282134"
          height="50"
          style="border-radius: 10px"
          class="btn-location"
        >
          <v-icon small dark color="#fff" class="mr-2">
            fa-solid fa-location-dot
          </v-icon>

          <div class="text-label" style="color: #fff">
            <b class="text-capitalize">Ciudad</b>
            <p v-if="city" class="text-capitalize">{{ city.name }}</p>
          </div>
          <img
            :src="country.img"
            class="ml-2 flag"
            style="border-radius: 5px"
            alt=""
            srcset=""
          />
        </v-btn>
      </div>
      <v-btn
        v-else
        text
        height="52"
        @click="dialogCountries = true"
        style="border-radius: 10px"
      >
        <v-icon small dark color="#fff" class="mr-2">
          fa-solid fa-location-dot
        </v-icon>
        Ubicación
      </v-btn>

      <v-menu v-if="user">
        <template v-slot:activator="{ on, attrs }">
  
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="#282134"
            height="52"
            class="pr-2 pl-2"
            style="border-radius: 10px"
          >
            <span class="text-capitalize"
              >{{ user.name }} <br />
              {{ user.surname }}
            </span>

            <v-badge
              overlap
              class="ml-2"
              color="#00cdbc"
              :value="valueOrders"
              :content="valueOrders"
            >
              <img :src="picture" class="photo" alt="" srcset=""
            /></v-badge>
          </v-btn>
        </template>
        <v-card max-width="300" color="#282134">
          <v-list nav color="#282134">
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="picture" alt="Users"> </v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  >{{ user.name }} {{ user.surname }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              class="mt-2"
              v-if="btn_checkout"
              @click="
                (dialogConfirmCloseCheckout = true), (pathBack = '/history')
              "
            >
              <span> Ver tickets para el evento</span>
            </v-list-item>
            <v-list-item
              class="mt-2"
              link
              to="/history"
              v-else
              :disabled="btn_checkout"
            >
              <span> Ver tickets para el evento</span>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-btn color="primary" class="mt-2" block @click="logOut">
                Salir
                <v-icon> </v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <pre-login v-else />
    </v-container>

    <v-dialog v-model="dialogCountries" max-width="400" persistent>
      <v-card>
        <v-card-title class="pr-12">
          Cambio de país / ciudad

          <v-btn
            icon
            absolute
            right
            top
            color="primary"
            @click="dialogCountries = !dialogCountries"
          >
            <v-icon small> fa fa-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider> </v-divider>
        <v-card-text class="pt-10">
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="selectedCountry"
                :items="countries"
                placeholder="Escoge el pais"
                filled
                style="border-radius: 10px"
                item-text="name"
                item-value=".key"
                rounded
                class="mx-4"
              />
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="selectedCity"
                :items="cities"
                placeholder="Escoge la ciudad"
                filled
                style="border-radius: 10px"
                :disabled="!selectedCountry"
                item-text="name"
                item-value=".key"
                rounded
                class="mx-4"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="dialogConfirmCloseCheckout"
      v-model="dialogConfirmCloseCheckout"
      persistent
      max-width="300"
    >
      <v-card>
        <v-card-title class="text-center">
          <span class="mx-auto">Atención </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6">
          <p class="text-center">
            Los ticket seleccionados serán <br />
            liberados al salir 😓
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="flex-center">
          <v-btn text @click="dialogConfirmCloseCheckout = false">
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="dialogConfirmCloseCheckout = false"
            link
            class="px-6"
            :to="pathBack"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import PreLogin from "../components/preLogin.vue";
export default {
  name: "Nav",
  components: {
    PreLogin,
  },
  data() {
    return {
      menu: false,
      CitySelect: null,
      countries: [],
      cities: [],
      btn_checkout: false,
      back: window.history.length,
      selectCountries: null,
      tickets: [],
      dialogCountries: false,
      selectedCountry: null,
      countriesSelect: null,
      selectedCity: null,
      isBack: false,
      dialogConfirmCloseCheckout: false,
      imgUser: require("@/assets/user.svg"),
      pathBack: "",
      valueOrders: 0,
    };
  },
  computed: {
    ...mapState([
      "user",
      "selectEvent",
      "orderRespal",
      "phoneUser",
      "SessionTimer",
      "sessionToken",
      "city",
      "country",
    ]),
    picture() {
      if (this.user.picture == "assets/imgs/avatar-default.svg") {
        return require("@/assets/user.svg");
      } else {
        return this.user.picture;
      }
    },
  },
  methods: {
    ...mapActions([
      "mode",
      "_user",
      "_country",
      "_city",
      "_codeArea",
      "_timerSession",
      "setPhone",
      "_selectEvent",
    ]),
    hasHistory() {
      return window.history.length > 2;
    },
    goHome() {
      this.$router.push("/");
    },
    logOut() {
      if (this.user) {
        fb.auth()
          .signOut()
          .then(() => {
            this._user(null);
            this._codeArea(null);
            this._timerSession(null);
            this.setPhone(null);
            this._selectEvent(null);
            if (this.$route.path != "/") {
              this.$router.push("/");
            }
          });
      }
    },
    quantityOrder() {
      this.valueOrders = 0;
      db.collection("tickets")
        .where("userId", "==", this.user[`.key`])
        .where("type", "==", "oVive")
        .where("status", "==", "available")
        .onSnapshot((response) => {
          let Orders = [];

          var tickets = response.docs.map((e) => {
            e[`.key`] = e.id;
            return e.data();
          });
          var arrayObjet = tickets.reduce(function (r, a) {
            const { orderId } = a;
            r[orderId] = r[orderId] ?? [];
            r[orderId].push(a);
            return r;
          }, Object.create(null));

          for (const key in arrayObjet) {
            if (Object.hasOwnProperty.call(arrayObjet, key)) {
              const element = arrayObjet[key];
              let item = {
                tickets: element,
              };
              Orders.push(item);
            }
          }
          this.valueOrders = Orders.length;
        });
    },
  },
  mounted() {
    this.$nextTick(async () => {
      // console.debug(this.$route.path);
      var path = {
        "/": () => {
          this.isBack = false;
          this.btn_checkout = false;
        },
        "/event": () => {
          this.isBack = true;
          this.pathBack = "/";
          this.btn_checkout = false;
        },
        "/checkout": () => {
          //  this.dialogConfirmCloseCheckout = true;
          this.isBack = true;
          this.pathBack = "/";
          this.btn_checkout = true;
        },
        "/history": () => {
          this.isBack = true;
          this.pathBack = "/";
          this.btn_checkout = false;
        },
      };

      path[this.$route.path]();

      if (!this.city && !this.country) {
        this.dialogCountries = true;
      }

      if (this.user) {
        this.quantityOrder();
      }

      await this.$binding(
        "countries",
        db
          .collection("countries")
          .where("deleted", "==", false)
          .where("active", "==", true)
      ).then((resp) => {
        // console.debug(resp);
        this.countriesSelect = resp[0];
      });
    });
  },
  watch: {
    $route(e) {
      var path = {
        "/": () => {
          this.isBack = false;
          this.btn_checkout = false;
        },
        "/event": () => {
          this.isBack = true;
          this.pathBack = "/";
          this.btn_checkout = false;
        },
        "/checkout": () => {
          this.isBack = true;
          this.pathBack = "/";
          this.btn_checkout = true;
        },
        "/history": () => {
          this.isBack = true;
          this.pathBack = "/";
          this.btn_checkout = false;
        },
      };
      path[e.path]();
    },
    selectedCountry(countryId) {
      this.countriesSelect = this.countries.find((x) => x[`.key`] == countryId);
      // console.debug(this.countriesSelect);
      this._country(this.countriesSelect);
      this.$binding(
        "cities",
        db
          .collection("cities")
          .where("deleted", "==", false)
          .where("activeInOvive", "==", true)
          .where("countryId", "==", countryId)
      );
    },
    selectedCity(key) {
      this.CitySelect = this.cities.find((x) => x[`.key`] == key);
      this._city(this.CitySelect);
      this.dialogCountries = false;
      if (this.$route.path != "/") {
        this.$router.push("/");
      }
    },
    user(e) {
      if (e != null) {
        this.quantityOrder();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";

html[data-scroll="0"] {
  .nav {
    width: 100%;
    position: fixed;
    height: 100px;
    top: 0px;

    background: #160819;
    background: linear-gradient(
      180deg,
      #1608197c 30%,
      rgba(0, 212, 255, 0) 90%
    );
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100px;
      img {
        height: 70px;
      }
    }
    .back {
      .logo-nav {
        margin-left: 10px;
        height: 50px;
        cursor: pointer;
        @include responsive(mobile) {
          height: 35px !important;
        }
      }
    }
  }
  .grap-locations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    .btn-location {
      .text-label {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        b {
          line-height: 30px;
        }
        p {
          line-height: 0px;
        }

        @include responsive(mobile) {
          display: none;
        }
      }
    }
  }
  .flag {
    height: 30px !important;
    @include responsive(mobile) {
      height: 20px !important;
    }
  }
}
.flag {
  height: 30px !important;
  @include responsive(mobile) {
    height: 20px !important;
  }
}
.nav {
  transition: 0.3s ease-in-out;
  width: 100%;
  position: fixed;
  height: 70px !important;
  top: 0px;
  background: #160819e2;
  //   filter: blur(2px);
  //   background: linear-gradient(180deg, #1608197c 30%, rgba(0, 212, 255, 0) 90%);
  .flex {
    display: flex;
    height: 70px !important;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    img {
      transition: 0.3s ease-in-out;
      height: 60px;
    }
  }
  .back {
    .logo-nav {
      margin-left: 10px;
      height: 50px;
      @include responsive(mobile) {
        height: 35px !important;
      }
    }
  }
}
.grap-locations {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  .btn-location {

  }

  .text-label {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    b {
      line-height: 30px;
    }
    p {
      line-height: 0px;
    }
    @include responsive(mobile) {
      display: none;
    }
  }
}
.user {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  span {
    font-weight: 400;
    font-size: 1em;
    margin-right: 10px;
  }
}
.photo {
  width: 40px;
  height: 40px !important;
  object-fit: cover;
  border-radius: 10px;
}
</style>